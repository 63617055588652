export const BASE = "https://giardinomiranda.altervista.org";

export const ROOT = '/';
export const IL_GIARDINO = '/il-giardino';
export const I_PERCORSI = 'i-percorsi';
export const IL_PERCORSO_DEGLI_ALBERI_SOPRAVVISUTI = '/il-percorso-degli-alberi-sopravvisuti';
export const IL_PERCORSO_DEI_GIUSTI = '/il-percorso-dei-giusti';
export const IL_PERCORSO_VIRGILIANO = '/il-percorso-virgiliano';
export const LE_SCHEDE_BOTANICHE = '/le-schede-botaniche';

export const PDF_FOLDER = BASE + "/pdf/";
export const ABETE_PDF = PDF_FOLDER + "Abete.pdf";
export const ACERO_PDF = PDF_FOLDER + "Acero.pdf";
export const AGRIFOGLIO_PDF = PDF_FOLDER + "Agrifoglio.pdf";
export const ALLORO_PDF = PDF_FOLDER + "Alloro.pdf";
export const CASTAGNO_PDF = PDF_FOLDER + "Castagno.pdf";
export const CARRUBO_PDF = PDF_FOLDER + "Carrubo.pdf";
export const CAMELIA_PDF = PDF_FOLDER + "Camelia.pdf";
export const CANFORO_PDF = PDF_FOLDER + "Canforo.pdf";
export const CEDRO_PDF = PDF_FOLDER + "Cedro.pdf";
export const CICAS_PDF = PDF_FOLDER + "Cicas.pdf";
export const CIPRESSO_PDF = PDF_FOLDER + "Cipresso.pdf";
export const CORBEZZOLO_PDF = PDF_FOLDER + "Corbezzolo.pdf";
export const EUCALIPTO_PDF = PDF_FOLDER + "Eucalipto.pdf";
export const GELSO_PDF = PDF_FOLDER + "Gelso.pdf";
export const GINEPRO_PDF = PDF_FOLDER + "Ginepro.pdf";
export const GINESTRA_PDF = PDF_FOLDER + "Ginestra.pdf";
export const GINKGO_PDF = PDF_FOLDER + "Ginkgo.pdf"
export const IBISCO_PDF = PDF_FOLDER + "Ibisco.pdf";
export const LECCIO_PDF = PDF_FOLDER + "Leccio.pdf";
export const LIMONE_PDF = PDF_FOLDER + "Limone.pdf";
export const LIGUSTRO_PDF = PDF_FOLDER + "Ligustro.pdf";
export const NESPOLO_PDF = PDF_FOLDER + "Nespolo.pdf";
export const NOCE_PDF = PDF_FOLDER + "noce.pdf";
export const MELIA_PDF = PDF_FOLDER + "Melia.pdf";
export const MELOGRANO_PDF = PDF_FOLDER + "Melograno.pdf";
export const MIMOSA_PDF = PDF_FOLDER + "Mimosa.pdf";
export const OLEANDRO_PDF = PDF_FOLDER + "Oleandro.pdf";
export const OLIVO_PDF = PDF_FOLDER + "Olivo.pdf";
export const PINO_PDF = PDF_FOLDER + "Pino_aleppo.pdf";
export const PIOPPO_PDF = PDF_FOLDER + "Pioppo.pdf";
export const PRUGNO_PDF = PDF_FOLDER + "Prugnolo_selvatico.pdf";
export const PUNGITOPO_PDF = PDF_FOLDER + "Pungitopo.pdf";
export const QUERCIA_PDF = PDF_FOLDER + "Roverella.pdf";
export const ROSASELVATICA_PDF = PDF_FOLDER + "Rosa_selvatica.pdf"
export const ROSMARINO_PDF = PDF_FOLDER + "Rosmarino.pdf";
export const SALICE_PDF = PDF_FOLDER + "Salice.pdf";
export const TAMERICE_PDF = PDF_FOLDER + "Tamerice.pdf";
export const TIGLIO_PDF = PDF_FOLDER + "Tiglio.pdf";
export const TIMO_PDF = PDF_FOLDER + "timo.pdf";
export const VIBURNO_PDF = PDF_FOLDER + "Viburno.pdf";
export const YUCCA_PDF = PDF_FOLDER + "Yucca.pdf";
export const BAGOLARO_PDF = PDF_FOLDER + "Bagolaro.pdf";
export const COLUTERIA_PDF = PDF_FOLDER + "Coluteria.pdf";
export const ROVERELLA_PDF = PDF_FOLDER + "roverella.pdf";
export const SUGHERA_PDF = PDF_FOLDER + "Sughera.pdf";
export const NARCISO_PDF = PDF_FOLDER + "Narciso.pdf";
export const TUIA_PDF = PDF_FOLDER + "Tuia.pdf"
export const ALBERODIGIUDA_PDF = PDF_FOLDER + "Albero_di_Giuda.pdf"
export const CARPINO_PDF = PDF_FOLDER + "Carpino.pdf";
export const CISTO_PNG = PDF_FOLDER + "Cisto.pdf";
export const FILLIREA_PNG = PDF_FOLDER + "Fillirea.pdf";
export const LENTISCO_PDF = PDF_FOLDER + "Lentisco.pdf";
export const MIRTO_PDF = PDF_FOLDER + "Mirto.pdf";
export const PITTOSPORO_PDF = PDF_FOLDER + "Pittosporo.pdf";
export const ERBACIPOLLINA_PDF = PDF_FOLDER + "Erba_cipollina.pdf";
export const LAVANDA_PDF = PDF_FOLDER + "Lavanda.pdf";
export const MENTA_PDF = PDF_FOLDER + "Menta.pdf";
export const ORIGANO_PDF = PDF_FOLDER + "Origano.pdf";
export const SALVIA_PDF = PDF_FOLDER + "Salvia.pdf";
export const AGAZZINO_PDF = PDF_FOLDER + "Agazzino.pdf";
export const MIRABOLANO_PDF = PDF_FOLDER + "Mirabolano.pdf";
export const PHOTINIA_PDF = PDF_FOLDER + "Photinia.pdf";
export const TEMERICA_PDF = PDF_FOLDER + "Temerica.pdf";
export const ALBICOCCO_PDF = PDF_FOLDER + "Albicocco.pdf";
export const ARANCIO_PDF = PDF_FOLDER + "Arancio.pdf";
export const KUMQUAT_PDF = PDF_FOLDER + "Kumquat.pdf";
export const MANDARINO_PDF = PDF_FOLDER + "Mandarino.pdf";
export const SUSINO_PDF = PDF_FOLDER + "Susino.pdf";
export const COCOSPLUMOSA_PDF = PDF_FOLDER + "Cocos_plumosa.pdf";
export const PALMANANA_PDF = PDF_FOLDER + "Palma_nana.pdf";
export const COTOGNASTROORIZZONTALE_PDF = PDF_FOLDER + "Cotognastro_orizzontale.pdf";

export const ANGIOSPERMEINTRODUZIONE_PDF = PDF_FOLDER + "Angiosperme_introduzione.pdf";
export const ARBORETOINTRODUZIONE_PDF = PDF_FOLDER + "Arboreto_introduzione.pdf";
export const FRUTTETOINTRODUZIONE_PDF = PDF_FOLDER + "Frutteto_introduzione.pdf";
export const PIANTEAROMATICHE_PDF = PDF_FOLDER + "Giardino_aromatico_introduzione.pdf";
export const GIMNOSPERMEINTRODUZIONE_PDF = PDF_FOLDER + "Gimnosperme_introduzione.pdf";
export const MACCHIAMEDITERRANEAINTRODUZIONE_PDF = PDF_FOLDER + "macchia_mediterranea_introduzione.pdf";
export const MELIAINTRODUZIONE_PDF = PDF_FOLDER + "melia_introduzione.pdf";
export const PALMETOINTRODUZIONE_PDF = PDF_FOLDER + "Palmeto_introduzione.pdf";
export const VIALEALLORIINTRODUZIONE_PDF = PDF_FOLDER + "Viale_allori_introduzione.pdf";
export const VIALELECCIINTRODUZIONE_PDF = PDF_FOLDER + "Viale_lecci_introduzione.pdf";

export const GIARDINO_VIRGILIANO_PDF = [
    PDF_FOLDER,
    ACERO_PDF,
    ALLORO_PDF,
    CASTAGNO_PDF,
    CEDRO_PDF,
    CIPRESSO_PDF,
    CORBEZZOLO_PDF,
    GELSO_PDF,
    GINEPRO_PDF,
    GINESTRA_PDF,
    IBISCO_PDF,
    LECCIO_PDF,
    LIMONE_PDF,
    LIGUSTRO_PDF,
    NOCE_PDF,
    OLEANDRO_PDF,
    OLIVO_PDF,
    PINO_PDF,
    PIOPPO_PDF,
    PRUGNO_PDF,
    PUNGITOPO_PDF, 
    QUERCIA_PDF,
    ROSMARINO_PDF, 
    SALICE_PDF,
    TAMERICE_PDF,
    TIGLIO_PDF,
    TIMO_PDF,
    VIBURNO_PDF
]
