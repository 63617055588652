import * as React from 'react';
import { useNavigate } from 'react-router';
import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Button,
    Typography
} from '@mui/material';



export const CustomCard = ({image, title, content, buttonText, path, pdf}) => {

  const navigate = useNavigate();

  return (
    <Card variant='elevation' sx={{ maxWidth: 345 }}>
      <CardMedia
      component="img"
        sx={{ height: 200, objectFit:"contain"}}
        image={image}
        title={title}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
            {title}            
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {content}
        </Typography>
      </CardContent>
      <CardActions sx={{justifyContent: "end"}}>
        {path ? 
          <Button size="small" onClick={()=>{navigate(path)}}>{buttonText}</Button>
          :
          <a href={pdf} withouth rel="noopener noreferrer" target='_blank'>
            <Button size='small'>{buttonText}</Button>
          </a>
        }
      </CardActions>
    </Card>
  );
}
