import {
    Box,
    Typography
} from '@mui/material'

export const Header = ({image}) => {
    return(
        <Box sx={{flexGrow: 1, display: "flex", height: "40vh"}}>
            <img src={image} style={{objectFit: "cover", minWidth: "100%", minHeight: "100%"}}/>
        </Box>
    )
}
