import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updatePage } from "../appSlice"
import Grid from '@mui/material/Unstable_Grid2';
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import {
    ABETE_PDF,
    CIPRESSO_PDF,
    AGRIFOGLIO_PDF,
    MELOGRANO_PDF,
    CANFORO_PDF,
    SALICE_PDF,
    OLIVO_PDF,
    MIMOSA_PDF,
    CARRUBO_PDF,
    TIGLIO_PDF,
    CORBEZZOLO_PDF,
    QUERCIA_PDF
} from "../common/constants/paths"
import { CustomCard } from "../common/components/card";

import arturoToscaniniIMG from "../res/img/percorso_giusti/arturo_toscanini.jpg"
import federicoDelPreteIMG from "../res/img/percorso_giusti/federico_delprete.jpg";
import feliciaImpastatoIMG from "../res/img/percorso_giusti/felicia_impastato.jpg";
import franciaMarquezIMG from "../res/img/percorso_giusti/francia_marquez.jpg";
import ginoBartaliIMG from "../res/img/percorso_giusti/gino_bartali.jpg";
import giovanniFalconeIMG from "../res/img/percorso_giusti/giovanni_falcone.jpg";
import marioPaciollaIMG from "../res/img/percorso_giusti/mario_paciolla.jpg";
import peppeDianaIMG from "../res/img/percorso_giusti/peppe_diana.jpg";
import pinoPuglieseIMG from "../res/img/percorso_giusti/pino_puglisi.jpg";
import robertoManciniIMG from "../res/img/percorso_giusti/roberto_mancini.jpg";
import wangariMaathaiIMG from "../res/img/percorso_giusti/wangari_maathai.jpg";


export const IlPercorsoDeiGiusti = ({}) => {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(updatePage("Il Percorso dei Giusti"));
    })

    return(
        <>
            <Box id="Percorsi-body" sx={{ marginTop:"20px", marginLeft: {xs: "5%", md: "15%"}, marginRight: {xs: "5%", md: "15%"}, marginBottom: "100px"}}>
                <Stack spacing={2}>
                    <Typography variant="h4" align="center">Il Percorso Dei Giusti</Typography>
                    <Typography align="justify">
                        L'istituzione di un percorso dei Giusti nel Giardinobiodiverso del Liceo Miranda ha l'obiettivo di
                        rendere il giardino un luogo di educazione alla cultura e alla memoria,
                        associando un albero adognuno dei Giusti individuati. Un luogo della memoria
                        attiva, dove è possibile ampliare il più possibile il messaggio dei Giusti,
                        permettendo di fornire ai giovani strumenti di conoscenza e approfondimento
                        sulle figure onorate.
                    </Typography>
                    <Typography align="justify">
                        I Giusti sono donne e uomini che hanno fatto del bene in ogni tempo e in ogni
                        luogo: hanno salvato vite umane, si sono battuti in favore dei diritti umani
                        durante i genocidi e hanno difesola dignità della persona, rifiutando di piegarsi
                        ai totalitarismi e alle discriminazioni tra esseri umani.
                        Sono persone che a un certo punto della loro vita, di fronte a un'ingiustizia o
                        alla persecuzionedi esseri umani, sono stati capaci di andare con coraggio in
                        soccorso dei sofferenti e di interrompere la catena del male di cui si sono visti
                        testimoni.
                    </Typography>
                    <Typography align="justify">
                        Il primo Giardino dei Giusti è sorto nel 1962 a Gerusalemme, per
                        commemorare i Giusti trale Nazioni, che hanno rischiato la loro vita per aiutare
                        degli ebrei.
                    </Typography>
                    <Typography align="justify">
                        Grazie poi all'associazione Gariwo (acronimo di Gardens of the Righteous
                        Worldwide), laForesta dei Giusti, sono stati istituiti Giardini in tutto il mondo
                        Il Parlamento europeo ha istituito nel 2012 la Giornata dei Giusti, che si celebra
                        il 6 marzo diogni anno, accogliendo l'appello lanciato dall'associazione Gariwo.
                    </Typography>
                    <Typography align="justify">
                        Il giorno 16 maggio 2022 il Liceo Scientifico-Linguistico Carlo Miranda di
                        Frattamaggioreha inaugurato il "Percorso dei Giusti" all'interno del Giardino
                        scolastico biodiverso, con il patrocinio del Presidente della Camera.
                        Ad ogni Giusto è stata associata una pianta e un pannello descrittivo.
                    </Typography>
                    <Typography variant="h4" align="center">I Giusti e i Loro Alberi</Typography>
                    
                    <Grid container spacing={3}>
                        <SchedeDeiGiusti 
                            subtitle="SHOAH E NAZISMO" 
                            title={"ARTURO TOSCANINI (1867 - 1957)"} 
                            content={"Genio della musica, patriottico e ribelle. Testimone dell’abbraccio tra libertà, arte e solidarietà."}
                            descritpion={"L'abete è un albero associato alla longevità. Esiste un abete, detto sonoro, per le ottime proprietà di amplificazione del suono del suo legno."}
                            cardTitle={"ABETE (Picea abiens)"}
                            cardImage={arturoToscaniniIMG}
                            pdf={ABETE_PDF}
                        />
                        <SchedeDeiGiusti 
                            subtitle="RESISTENZA ALLA MAFIA" 
                            title={"DON PINO PUGLISI (1937 - 1993)"} 
                            content={"Il prete ucciso perché non ha rinunciato a denunciare la mafia durante le sue omelie Il coraggio della parola e della fede contro le mafie."}
                            descritpion={"Il cipresso è simbolo dell'immortalità, emblema della vita eterna dopo la morte."}
                            cardTitle={"CIPRESSO (Cupressus)"}
                            cardImage={pinoPuglieseIMG}
                            pdf={CIPRESSO_PDF}
                        />
                        <SchedeDeiGiusti 
                            subtitle="RESISTENZA ALLA MAFIA" 
                            title={"FEDERICO DEL PRETE (1957 - 2002)"} 
                            content={"Sindacalista di Frattamaggiore ucciso dalla camorra, medaglia d'oro al valor civile. Una vita sacrificata per difendere i vessati dallacamorra in nome dell’onestà."}
                            descritpion={"Il corbezzolo è una pianta sempreverde, simbolo di immortalità, eternità e ospitalità."}
                            cardTitle={"AGRIFOGLIO (Ilex aquifolium)"}
                            cardImage={federicoDelPreteIMG}
                            pdf={CORBEZZOLO_PDF}
                        />             
                        <SchedeDeiGiusti 
                            subtitle="RESISTENZA ALLA MAFIA" 
                            title={"DON PEPPE DIANA (1958 - 1994)"} 
                            content={"Sacerdote di Casal di Principe assassinato dallacamorra per il suo impegno antimafia. Ricordando la sua lotta all'omertà: “Per amore del mio popolo non tacerò”"}
                            descritpion={"Il melograno è imbolo di fertilità e produttività per i suoi numerosi semi."}
                            cardTitle={"MELOGRANO ( Punica granatum)"}
                            cardImage={peppeDianaIMG}
                            pdf={MELOGRANO_PDF}
                        />                     
                        <SchedeDeiGiusti 
                            subtitle="RESISTENZA ALLA MAFIA" 
                            title={"FELICIA BARTOLOTTA IMPASTATO (1916 - 2004)"} 
                            content={"Madre di Peppino Impastato, tenace madre, testimone di Giustizia senza vendetta."}
                            descritpion={"Il canforo è considerato l'albero della vita, perché è stata la prima pianta a ricrescere dopo il bombardamento atomico di Hiroshima."}
                            cardTitle={"CANFORO"}
                            cardImage={feliciaImpastatoIMG}
                            pdf={CANFORO_PDF}
                        />
                        <SchedeDeiGiusti 
                            subtitle="RESISTENZA ALLA MAFIA" 
                            title={"GIOVANNI FALCONE (1939 - 1992)"} 
                            content={"Giudice antimafia  è stato una delle personalità più importanti e prestigiose nella lotta alla mafia in Italia e a livello internazionale. Fu ucciso da Cosa nostra insieme alla moglie Francesca Morvillo e ai tre uomini della scorta"}
                            descritpion={"L'ulivo è universalmente emblema di pace, forza e vittoria."}
                            cardTitle={"ULIVO (Olea europaea)"}
                            cardImage={giovanniFalconeIMG}
                            pdf={OLIVO_PDF}
                        />            
                        <SchedeDeiGiusti 
                            subtitle="GIUSTI PER L'AMBIENTE" 
                            title={"ROBERTO MANCINI (1961 - 2014)"} 
                            content={"Primo poliziotto che con la sua squadra ha indagato sullo sversamento illegale di rifiuti speciali e tossici nella Terra dei fuochi e sulleattività della camorra collegate. Ucciso dai veleni della terra deifuochi, Vivo per sempre perché difensore del diritto ad una Terra salubre."}
                            descritpion={"Il salice è simbolo di immortalità. I suoi rami penduli cidanno un senso di protezione, come Roberto Mancini ha protetto la nostra Terra"}
                            cardTitle={"SALICE PIANGENTE (Salix babylonica)"}
                            cardImage={robertoManciniIMG}
                            pdf={SALICE_PDF}
                        />
                        <SchedeDeiGiusti 
                            subtitle="GIUSTI PER L'AMBIENTE" 
                            title={"WANGARI MAATHAI (1940 - 2011)"} 
                            content={'biologa, ambientalista e attivista politica keniota, la "signora degli alberi", voce simbolo della lotta per la pace e del movimento “green belt". Nel 2004 è la prima donna africana a vincere il Premio Nobel per la pace, per “il suo contributo allo sviluppo sostenibile, alla democrazia e alla pace” L\'amore per la salvaguardia dell\'ambiente che diventa Azione concreta'}
                            descritpion={"L'ulivo è universalmente emblema di pace, forza, vittoria."}
                            cardTitle={"ULIVO (Olea europaea)"}
                            cardImage={wangariMaathaiIMG}
                            pdf={OLIVO_PDF}
                        />
                        <SchedeDeiGiusti 
                            subtitle="GIUSTI PER L'AMBIENTE" 
                            title={"FRANCIA MARQUEZ (1981)"} 
                            content={"Attivista ambientale colombiana e leader delle comunità afro-colombiane. Nel 2018 Premio Goldman per l'ambiente per il suo impegno contro leminiere d'oro illegali. L'anteporre l'amore per l'ambiente al profitto sfrenato"}
                            descritpion={"La mimosa è simbolo della lotta delle donne per le rivendicazioni di libertà e di diritti."}
                            cardTitle={"MIMOSA (Acacia dealbata)"}
                            cardImage={franciaMarquezIMG}
                            pdf={MIMOSA_PDF}
                        />
                        <SchedeDeiGiusti 
                            subtitle="GIUSTI NELLO SPORT" 
                            title={"GINO BARTALI (1914 - 2000)"} 
                            content={"Celebre ciclista e grande uomo. Ha sfruttato la sua posizione per aiutare numerosi ebrei perseguitati durante l'occupazione nazista in Toscana. Medaglia d'oro al merito civile nel 2005. Campione su due ruote e nella vita."}
                            descritpion={"Il carrubo è simbolo del ricordo dei Giusti tra le Nazioni nel Giardino dei Giusti di Gerusalemme, sorto nel 1962 presso il Mausoleo di Yad Vashem, il luogo della memoria della Shoah. Gli alberi di carrubo sono stati scelti per commemorare i Giusti tra le Nazioni"}
                            cardTitle={"CARRUBO (Ceratonia siliqua)"}
                            cardImage={ginoBartaliIMG}
                            pdf={CARRUBO_PDF}
                        />
                        <SchedeDeiGiusti 
                            subtitle="FIGURE CHE SI SONO BATTUTE PER I DIRITTI UMANI" 
                            title={"MARIO PACIOLLA (1987 - 2020)"} 
                            content={"Giovane napoletano cooperante dell'ONU Un giovane dai valori antichi. Giornalista, attivista e funzionario italiano, morto in circostanze misteriose durante l'esercizio delle sue funzioni."}
                            descritpion={"La quercia da sughero è simbolo di resistenza, grazie alle sue radici che affondano a grandi profondità e di longevità perché può diventare plurisecolare."}
                            cardTitle={"QUERCIA DA SUGHERO"}
                            cardImage={marioPaciollaIMG}
                            pdf={QUERCIA_PDF}
                        />                                                              
                    </Grid>

                </Stack>
            </Box>
        </>
    )
}


const SchedeDeiGiusti = ({subtitle, title, content, descritpion, cardImage, cardTitle, cardContent, pdf}) => {
    return(
        <>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Stack spacing={1} direction={"column"}>
                    <Typography variant="p" fontWeight={"bold"}>{subtitle}</Typography>
                    <Typography variant="h5">{title}</Typography>
                    <Typography align="justify">{content}</Typography>
                    <Typography align="justify">{descritpion}</Typography>
                </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <CustomCard
                    image={cardImage} 
                    title={cardTitle} 
                    content={cardContent} 
                    buttonText={"Apri il PDF"}
                    pdf={pdf}
                />
            </Grid>
        </>
    )
}