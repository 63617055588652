import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updatePage } from "../appSlice"
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CustomCard } from "../common/components/card";
import {
    CAMELIA_PDF,
    CANFORO_PDF,
    CICAS_PDF,
    EUCALIPTO_PDF,
    GINKGO_PDF,
    MELIA_PDF,
    OLEANDRO_PDF,
    SALICE_PDF
} from "../common/constants/paths";
import saliceIMG from "../res/img/salice.jpg";
import OleandroIMG from "../res/img/oleandro.jpg";
import canforoIMG from "../res/img/canforo.jpg";
import eucaliptoIMG from "../res/img/eucalipto.jpg";
import cicasIMG from "../res/img/cicas.jpg";
import cameliaIMG from "../res/img/camelia.jpg";
import meliaIMG from "../res/img/melia.jpg";
import ginkgoIMG from "../res/img/ginkgo.jpg";


export const IlPercorsoDegliAlberiSopravvisuti = ({}) => {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(updatePage("Il Percorso Degli Alberi Sopravvisuti"));
    })

    return(
        <Box id="Percorsi-body" sx={{ marginTop:"20px", marginLeft: {xs: "5%", md: "15%"}, marginRight: {xs: "5%", md: "15%"}, marginBottom: "100px"}}>
            <Stack spacing={2}>
                <Typography variant="h4" align="center">Il Percorso degli alberi sopravvisuti</Typography>
                <Typography align="justify">
                    Hibakujumoku (detto anche A-bombed tree in inglese) è il termine giapponese per indicare un albero che è stato esposto al bombardamento atomico di Hiroshima 
                    e Nagasaki nel 1945 ed è sopravvissuto oppure ha rigermogliato dalle sue radici. Il termine è composto da hibaku che significa "bombardato, esposto a radiazione nucleare" 
                    e jumoku con il significato di "albero" o "bosco".
                </Typography>
                <Typography align="justify">
                    L’esplosione delle due atomiche che hanno distrutto Hiroshima il 6 agosto e Nagasaki il 9 agosto 1945, nei primi 3 secondi di impatto, ha rilasciato, 
                    nel raggio di 3 chilometri dall’epicentro, una enorme quantità di calore, circa 40 volte quello del sole. Il livello iniziale di radiazione 
                    all’epicentro è stato calcolato di circa 240 Gy (Il gray ,Gy, è l'unità di misura della dose assorbita di radiazione del Sistema Internazionale. 
                    Il gray ha sostituito il Rad, talvolta utilizzata; vale: 1 Gy = 100 Rad).
                </Typography>
                <Typography align="justify">
                    All’epicentro e nelle aree limitrofe la distruzione è stata totale, ad alcune centinaia di metri alcune piante sono sopravvissute 
                    sebbene notevolmente danneggiate.
                </Typography>
                <Typography align="justify">
                    Lo scienziato del Manhattan Project , Harold Jacobsen, sostenne che i luoghi colpiti sarebbero rimasti senza forme di vita per 75 anni. 
                    Ma la natura è sorprendente: già nella primavera  successiva, protetti dallo spessore del tronco o dalla profondità delle radici, 
                    iniziarono a spuntare dei germogli ad alberi, sia a Hiroshima che a Nagasaki, che si trovavano a circa 2 chilometri dall'epicentro dell'esplosione. 
                </Typography>
                <Typography align="justify">
                    In una intervista, Stefano Mancuso, fisiologo vegetale e direttore del Laboratorio internazionale di neurobiologia vegetale
                    dell’Università di Firenze, spiega «Uno studio degli anni '70 riportava addirittura di alberi sopravvissuti in un raggio di 500 metri dall'epicentro, 
                    un fatto straordinario perché si pensava che all'interno di quell'area non potesse sopravvivere nulla». «A posteriori sappiamo che ciò può essere dovuto 
                    al fatto che alcune parti interrate degli alberi sono state protette dallo strato di terra, oppure perché sul lato non irradiato, protetto dallo spessore 
                    del tronco, qualcosa è sopravvissuto. Quegli esemplari sono rinati perché le piante non sono un "unico organismo", come gli animali: si sono invece evolute 
                    in uno schema che potremmo definire "modulare" per sopravvivere alla predazione di animali capaci di nutrirsi anche del 90% di una pianta. 
                    Con una semplificazione, potremmo paragonarle a colonie di insetti», conclude Mancuso.
                </Typography>
                <Typography align="justify">
                    Nel suo libro, Plant revolution, Stefano Mancuso, spiega che la capacità di sopravvivenza e rigenerazione delle piante e, in particolare, 
                    di sopravvivere agli incendi, capacità posseduta in misura superiore rispetto al mondo animale, è da porsi in relazione alla 
                    struttura modulare dei vegetali che distribuiscono, sull’intero corpo o sue ampie porzioni, le funzioni che nel mondo animale sono 
                    concentrate prevalentemente in organi specifici. Questa struttura si è evoluta nel tempo per la necessità di sopravvivere non solo 
                    alle catastrofi ma soprattutto ai predatori.
                </Typography>
                <Typography align="justify">
                    In generale una maggiore capacità di rigenerazione è stata rilevata nelle latifoglie, mentre le aghifoglie, 
                    che erano apparentemente sopravvissute in numero maggiore, appassirono completamente e morirono entro l’anno successivo.
                </Typography>
                <Typography align="justify">
                    Studi realizzati da ricercatori giapponesi negli anni immediatamente successivi ai bombardamenti atomici del 1945, 
                    hanno riscontrato numerosi casi di alberi "hibakujumoku" sia a Nagasaki che ad Hiroshima e hanno redatto una lista delle specie 
                    che sono risultate detenere un elevato livello di capacità di sopravvivenza e rigenerazione. Sono tutti identificati con una apposita targa. 
                </Typography>
                <Typography align="justify">
                    A Hiroshima sono circa 170 di 32 differenti specie. Tra questi un oleandro che è stato designato fiore ufficiale di Hiroshima per la sua estrema vitalità. 
                    L'albero più vicino alla zona dell'esplosione è un salice piangente, rinato dalle sue radici dopo essere stato quasi completamente distrutto, 
                    ed è tuttora in vita.
                </Typography>
                <Typography variant="h5" align="center">Alberi Hibakujumoku simbolo di rinascita</Typography>
                <Typography align="justify">
                    Oggi i semi degli hibaku jumoko sono condivisi dagli abitanti di Hiroshima e piantati in Giappone e in altre parti del mondo, 
                    in un atto simbolico che richiama la distruzione e la forza della vita. Sono germogliati, presso l'Orto botanico dell'Università degli Studi di Perugia, 
                    alcuni semi di Hibaku jumoku  sopravvissuti alle devastazioni di Hiroshima e Nagasaki, hanno mostrato una sorprendente vitalità. 
                    Sono nati per ora 40 esemplari 27 dell'albero Aphananthe aspera, chiamato "Muku" in Giappone e 13 del più famoso fossile vivente del mondo vegetale: 
                    il Ginkgo biloba. Dotato di un'incredibile resistenza e simbolo di longevità e resilienza, in alcuni paesi orientali, è considerato un albero sacro 
                    e i monaci lo coltivano in prossimità di templi e luoghi di culto.
                </Typography>
                <Typography variant="h5" align="center">Le piante del percorso</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cameliaIMG} 
                            title="Camelia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CAMELIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={canforoIMG} 
                            title="Canforo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CANFORO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cicasIMG} 
                            title="Cycas" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CICAS_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={eucaliptoIMG} 
                            title="Eucalipto" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={EUCALIPTO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={ginkgoIMG} 
                            title="Ginkgo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={GINKGO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard  
                            image={meliaIMG}
                            title="Albero dei rosari" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MELIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard  
                            image={OleandroIMG}
                            title="Oleandro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={OLEANDRO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={saliceIMG} 
                            title="Salice" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={SALICE_PDF}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}