import { createSlice } from "@reduxjs/toolkit";

export const appSlice = createSlice({
    name: 'giardinomiranda',
    initialState: {
        selectedPage: "Homepage"
    },
    reducers: {
        updatePage: (state, action) => {
            state.selectedPage = action.payload;
        }
    }
})

export const {updatePage} = appSlice.actions
export default appSlice.reducer
