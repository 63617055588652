import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updatePage } from "../appSlice"
import {
    Box,
    Typography,
    Stack
} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import { CustomCard } from "../common/components/card";
import { 
    ACERO_PDF, 
    ALLORO_PDF, 
    CASTAGNO_PDF, 
    CEDRO_PDF, 
    CIPRESSO_PDF, 
    CORBEZZOLO_PDF,
    GELSO_PDF, 
    GINEPRO_PDF, 
    GINESTRA_PDF, 
    IBISCO_PDF, 
    LECCIO_PDF, 
    LIGUSTRO_PDF, 
    LIMONE_PDF, 
    NOCE_PDF, 
    OLEANDRO_PDF, 
    OLIVO_PDF, 
    PINO_PDF, 
    PIOPPO_PDF,
    PRUGNO_PDF,
    PUNGITOPO_PDF,
    QUERCIA_PDF,
    ROSMARINO_PDF,
    SALICE_PDF,
    TAMERICE_PDF,
    TIGLIO_PDF,
    TIMO_PDF,
    VIBURNO_PDF,
    ABETE_PDF,
    AGRIFOGLIO_PDF,
    MELOGRANO_PDF,
    CANFORO_PDF,
    MIMOSA_PDF,
    CARRUBO_PDF,
    EUCALIPTO_PDF,
    NESPOLO_PDF,
    ROSASELVATICA_PDF,
    YUCCA_PDF,
    BAGOLARO_PDF,
    COLUTERIA_PDF,
    MELIA_PDF,
    ROVERELLA_PDF,
    SUGHERA_PDF,
    NARCISO_PDF,
    CICAS_PDF,
    GINKGO_PDF,
    TUIA_PDF,
    ALBERODIGIUDA_PDF,
    CAMELIA_PDF,
    CARPINO_PDF,
    CISTO_PNG,
    FILLIREA_PNG,
    LENTISCO_PDF,
    MIRTO_PDF,
    PITTOSPORO_PDF,
    ERBACIPOLLINA_PDF,
    LAVANDA_PDF,
    MENTA_PDF,
    ORIGANO_PDF,
    SALVIA_PDF,
    AGAZZINO_PDF,
    MIRABOLANO_PDF,
    PHOTINIA_PDF,
    ALBICOCCO_PDF,
    ARANCIO_PDF,
    KUMQUAT_PDF,
    MANDARINO_PDF,
    SUSINO_PDF,
    COCOSPLUMOSA_PDF,
    PALMANANA_PDF,
    COTOGNASTROORIZZONTALE_PDF,
    ANGIOSPERMEINTRODUZIONE_PDF,
    ARBORETOINTRODUZIONE_PDF,
    GIMNOSPERMEINTRODUZIONE_PDF,
    MACCHIAMEDITERRANEAINTRODUZIONE_PDF,
    PIANTEAROMATICHE_PDF,
    VIALEALLORIINTRODUZIONE_PDF,
    VIALELECCIINTRODUZIONE_PDF,
    FRUTTETOINTRODUZIONE_PDF,
    PALMETOINTRODUZIONE_PDF,
    MELIAINTRODUZIONE_PDF
} from "../common/constants/paths";
import aceroIMG from "../res/img/acero.jpg";
import alloroIMG from "../res/img/alloro.jpg";
import castagnoIMG from "../res/img/castagno.jpg";
import cedroIMG from "../res/img/cedro.jpg";
import cipressoIMG from "../res/img/cipresso.jpg";
import corbezzoloIMG from "../res/img/corbezzolo.jpg";
import gelsoIMG from "../res/img/gelso.jpg";
import gineproIMG from "../res/img/ginepro.webp";
import ginestraIMG from "../res/img/ginestra.jpg";
import ibiscoIMG from "../res/img/ibisco.jpg";
import leccioIMG from "../res/img/leccio.jpg";
import limoneIMG from "../res/img/limone.jpg";
import ligustroIMG from "../res/img/ligustro.jpg";
import noceIMG from "../res/img/noce.jpg";
import OleandroIMG from "../res/img/oleandro.jpg";
import olivoIMG from "../res/img/olivo.jpg";
import pinoIMG from "../res/img/pino.jpg";
import pioppoIMG from "../res/img/pioppo.jpg";
import prugnoIMG from "../res/img/prugnolo_selvatico.jpg";
import pungitopoIMG from "../res/img/pungitopo.jpg";
import querciaIMG from "../res/img/quercia.jpg"
import rosmarinoIMG from "../res/img/rosmarino.jpg";
import saliceIMG from "../res/img/salice.jpg";
import tiglioIMG from "../res/img/tiglio.jpg";
import timoIMG from "../res/img/timo.jpg";
import viburnoIMG from "../res/img/viburno.jpg";
import abeteIMG from "../res/img/abete.png"
import agrifoglioIMG from "../res/img/agrifoglio.jpg";
import melogranoIMG from "../res/img/melograno.jpg";
import canforoIMG from "../res/img/canforo.jpg";
import mimosaIMG from "../res/img/mimosa.jpg";
import carruboIMG from "../res/img/carrubo.jpg";
import eucaliptoIMG from "../res/img/eucalipto.jpg";
import cicasIMG from "../res/img/cicas.jpg";
import cameliaIMG from "../res/img/camelia.jpg";
import meliaIMG from "../res/img/melia.jpg";
import ginkgoIMG from "../res/img/ginkgo.jpg";
import nespoloIMG from "../res/img/nespolo.jpg"
import rosaSelvaticaIMG from "../res/img/rosa_selvatica.jpg";
import yuccaIMG from "../res/img/yucca.jpg";
import bagolaroIMG from "../res/img/bagolaro.jpg";
import coluteriaIMG from "../res/img/coluteria.jpg";
import roverellaIMG from "../res/img/roverella.jpg";
import sugheraIMG from "../res/img/sughera.jpg";
import narcisoIMG from "../res/img/narciso.jpg";
import pervincaIMG from "../res/img/pervinca.jpg";
import tuiaIMG from "../res/img/tuia.jpg";
import alberoDiGiudaIMG from "../res/img/albero_di_giuda.jpg";
import carpinoIMG from "../res/img/carpino.jpg";
import cistoIMG from "../res/img/cisto.jpg";
import fillireaIMG from "../res/img/fillirea.jpg";
import lentiscoIMG from "../res/img/lentisco.jpg";
import mirtoIMG from "../res/img/mirto.jpg";
import pittosporoIMG from "../res/img/pittosporo.jpg";
import erbaCipollinaIMG from "../res/img/erba_cipollina.jpg"
import lavandaIMG from "../res/img/lavanda.jpg";
import mentaIMG from "../res/img/menta.jpg";
import origanoIMG from "../res/img/origano.jpg";
import salviaIMG from "../res/img/salvia.jpg";
import agazzinoIMG from "../res/img/agazzino.jpg";
import mirabolanoIMG from "../res/img/mirabolano.jpg";
import photiniaIMG from "../res/img/photinia.jpg";
import temericaIMG from "../res/img/tamerice.jpg";
import albicoccoIMG from "../res/img/albicocco.jpg";
import kumquatIMG from "../res/img/kumquat.jpg";
import mandarinoIMG from "../res/img/mandarino.png";
import arancioIMG from "../res/img/arancio.jpg";
import susinoIMG from "../res/img/susino.jpg";
import cocosPulmosaIMG from "../res/img/cocos_plumosa.jpg";
import palmaNanaIMG from "../res/img/palma_nana.png";
import cotognastroOrizzontaleIMG from "../res/img/Cotognastro_orizzontale.png";
import angiospermeIMG from "../res/img/intoduzione_schede/angiosperme.png";
import arboertoIMG from "../res/img/intoduzione_schede/arboreto.png";
import fruttetoIMG from "../res/img/intoduzione_schede/frutteto.png";
import gimnospermeIMG from "../res/img/intoduzione_schede/gimnosperme.png";
import macchiaMediterraneaIMG from "../res/img/intoduzione_schede/macchia_mediterranea.png";
import melieIMG from "../res/img/intoduzione_schede/melie.png";
import palmetoIMG from "../res/img/intoduzione_schede/palmeto.png";
import pianteAromaticheIMG from "../res/img/intoduzione_schede/piante_aromatiche.png";
import vialeAlloriIMG from "../res/img/intoduzione_schede/viale_allori.png";
import vialeLecciIMG from "../res/img/intoduzione_schede/viale_lecci.png";


export const LeSchedeBotaniche = ({}) => {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(updatePage("Le SchedeBotaniche"));
    })

    return(
        <Box id="SchedeBotaniche-body" sx={{ marginTop:"20px", marginLeft: {xs: "5%", md: "15%"}, marginRight: {xs: "5%", md: "15%"}, marginBottom: "100px"}}>
            <Stack spacing={2}>
                <Typography variant="h4" align="center">Le schede Botaniche</Typography>
                <>
                    <Typography align="justify">
                        La guida è nata dall’esigenza di raccontare la storia dell’importante ed educativa esperienza del “Giardino Biodiverso” e di farla conoscere a tutte le componenti della scuola e ai cittadini di Frattamaggiore.
                        Si è concretizzata durante il percorso PCTO “Gestione di un ambiente naturale” svolto in collaborazione con Legambiente Geofilos di Succivo dagli alunni del triennio delle sezioni B, C, G e H, durante le attività svolte nel corso dell’ a.s. 2020/21,seguiti dai tutor scolastici, proff. Mariagrazia Del Prete, Maddalena Diana, Rosario Fiorentino e Filomena Ferone e dai tutor di Legambiente, Carla Petito e Bernardo Diana.
                    </Typography>
                    <Typography align="justify">
                        La supervisione delle schede botaniche è stata curata dalla prof.ssa Mariagrazia Del Prete, la loro parte grafica è stata realizzata in collaborazione con gli  alunni Matteo Arnese, Francesco Paolo Bencivenga, Adele Perrotta e Rocco Vitale della classe 5^ B (a.s.2020-2021).
                        Alla realizzazione del sito hanno contribuito Matteo Arnese e Dario Galiani.
                    </Typography>
                    <Typography align="justify">
                        La conclusione del lavoro grafico è stata affidata agli alunni del PON “Il Giardino scolastico tra cultura e memoria”, nell’ a.s. 2022-23
                    </Typography>
                    <Typography align="justify">
                        Nella realizzazione delle schede ci si è lasciati guidare dalla consapevolezza che la natura costituisce da sempre una fonte privilegiata di ispirazione per gli artisti, ognuno dei quali la interpreta secondo la propria cultura e sensibilità.
                    </Typography>
                    <Typography align="justify">
                        Da qui l’idea di creare un itinerario guidato per guardare al mondo vegetale focalizzando l’attenzione dal punto di vista delle opere degli artisti. Si è andati alla ricerca di scrittori e poeti che hanno ammirato e descritto le piante presenti all’interno del nostro giardino e che ne hanno evidenziato l’utilità per l’uomo, il loro legame con la mitologia e la loro storia.
                    </Typography>
                    <Typography align="justify">
                        La valenza culturale di tale guida può essere individuata proprio nell’essere partiti dalla descrizione delle piante del giardino biodiverso, dall’etimologia del nome scientifico per poi immergersi nel mondo della letteratura e dell’arte
                    </Typography>
                    <Typography align="justify">
                        Nelle schede botaniche sono indicate  applicazioni farmaceutiche e usi alimentari solo a scopo informativo, non rappresentano in alcun modo prescrizione di tipo medico.
                        Si declina pertanto ogni responsabilità sul loro utilizzo a scopo curativo, estetico o alimentare.
                    </Typography>
                </>
                <Typography variant="h5" align="center">Area delle Angiosperme</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={angiospermeIMG} 
                            title="Angiosperme: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ANGIOSPERMEINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={eucaliptoIMG} 
                            title="Eucalipto" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={EUCALIPTO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={melogranoIMG} 
                            title="Melograno" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MELOGRANO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={nespoloIMG} 
                            title="Nespolo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={NESPOLO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={rosaSelvaticaIMG} 
                            title="Rosa Selvatica" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ROSASELVATICA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={yuccaIMG} 
                            title="Yucca" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={YUCCA_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Arboreto</Typography>
                <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={arboertoIMG} 
                            title="Arboreto: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ARBORETOINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={aceroIMG} 
                            title="Acero" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ACERO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={bagolaroIMG} 
                            title="Bagolaro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={BAGOLARO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={canforoIMG} 
                            title="Canforo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CANFORO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={castagnoIMG} 
                            title="Castagno" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CASTAGNO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={coluteriaIMG} 
                            title="Coluteria" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={COLUTERIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={ligustroIMG} 
                            title="Ligustro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={LIGUSTRO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={noceIMG} 
                            title="Noce" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={NOCE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={pioppoIMG} 
                            title="Pioppo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PIOPPO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={querciaIMG} 
                            title="Quercia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={QUERCIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={roverellaIMG} 
                            title="Roverella" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ROVERELLA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={saliceIMG} 
                            title="Salice" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={SALICE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={sugheraIMG} 
                            title="Sughera" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={SUGHERA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={tiglioIMG} 
                            title="Tiglio" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={TIGLIO_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Area della Ginestra</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={ginestraIMG} 
                            title="Ginestra" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={GINESTRA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={narcisoIMG} 
                            title="Narciso" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={NARCISO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={pervincaIMG} 
                            title="Pervinca" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={""}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Area delle Gimnosperme</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={gimnospermeIMG} 
                            title="Gimnosperme: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={GIMNOSPERMEINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={abeteIMG} 
                            title="Abete" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ABETE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cedroIMG} 
                            title="Cedro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CEDRO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cicasIMG} 
                            title="Cicas" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CICAS_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cipressoIMG} 
                            title="Cipresso" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CIPRESSO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={gineproIMG} 
                            title="Ginepro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={GINEPRO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={ginkgoIMG} 
                            title="Ginkgo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={GINKGO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={pinoIMG} 
                            title="Pino" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PINO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={tuiaIMG} 
                            title="Tuia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={TUIA_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Area della Macchia Mediterranea</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={macchiaMediterraneaIMG} 
                            title="Macchia Mediterranea: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MACCHIAMEDITERRANEAINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={agrifoglioIMG} 
                            title="Agrifoglio" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={AGRIFOGLIO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={alberoDiGiudaIMG} 
                            title="Albero di Giuda" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ALBERODIGIUDA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={alloroIMG} 
                            title="Alloro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ALLORO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cameliaIMG} 
                            title="Camelia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CAMELIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={carpinoIMG} 
                            title="Carpino" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CARPINO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={carruboIMG} 
                            title="Carrubo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CARRUBO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cistoIMG} 
                            title="Cisto" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CISTO_PNG}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={corbezzoloIMG} 
                            title="Corbezzolo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CORBEZZOLO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={fillireaIMG} 
                            title="Fillirea" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={FILLIREA_PNG}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={lentiscoIMG} 
                            title="Lentisco" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={LENTISCO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={mirtoIMG} 
                            title="Mirto" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MIRTO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={OleandroIMG} 
                            title="Oleandro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={OLEANDRO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={olivoIMG} 
                            title="Olivo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={OLIVO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={pittosporoIMG} 
                            title="Pittosporo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PITTOSPORO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={pungitopoIMG} 
                            title="Pungitopo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PUNGITOPO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={viburnoIMG} 
                            title="Viburno" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={VIBURNO_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Il Giardino Aromatico</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={pianteAromaticheIMG} 
                            title="Piante Aromatiche: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PIANTEAROMATICHE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={erbaCipollinaIMG} 
                            title="Erba Cipollina" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ERBACIPOLLINA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={lavandaIMG} 
                            title="Lavanda" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={LAVANDA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={mentaIMG} 
                            title="Menta" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MENTA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={origanoIMG} 
                            title="Origano" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ORIGANO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={rosmarinoIMG} 
                            title="Rosmarino" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ROSMARINO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={salviaIMG} 
                            title="Salvia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={SALVIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={timoIMG} 
                            title="Timo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={TIMO_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Viale degli Allori</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={vialeAlloriIMG} 
                            title="Viale Allori: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={VIALEALLORIINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={agazzinoIMG} 
                            title="Agazzino" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={AGAZZINO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={alloroIMG} 
                            title="Alloro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ALLORO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={mimosaIMG} 
                            title="Mimosa" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MIMOSA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={mirabolanoIMG} 
                            title="Mirabolano" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MIRABOLANO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={photiniaIMG} 
                            title="Photinia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PHOTINIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={prugnoIMG} 
                            title="Prugno Selvatico" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PRUGNO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={temericaIMG} 
                            title="Tamerice" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={TAMERICE_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Viale dei Lecci</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={vialeLecciIMG} 
                            title="Viale Lecci: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={VIALELECCIINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={gelsoIMG} 
                            title="Gelso" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={GELSO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={ibiscoIMG} 
                            title="Ibisco" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={IBISCO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={leccioIMG} 
                            title="Leccio" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={LECCIO_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Frutteto</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={fruttetoIMG} 
                            title="Frutteto: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={FRUTTETOINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={albicoccoIMG} 
                            title="Albicocco" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ALBICOCCO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={arancioIMG} 
                            title="Arancio" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={ARANCIO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={kumquatIMG} 
                            title="Kumquat" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={KUMQUAT_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={limoneIMG} 
                            title="Limone" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={LIMONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={mandarinoIMG} 
                            title="Mandarino" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MANDARINO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={susinoIMG} 
                            title="Susino" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={SUSINO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={olivoIMG} 
                            title="Ulivo" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={OLIVO_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Palmeto</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={palmetoIMG} 
                            title="Palmeto: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PALMETOINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cocosPulmosaIMG} 
                            title="Cocos Plumosa" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={COCOSPLUMOSA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={palmaNanaIMG} 
                            title="Palma Nana" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={PALMANANA_PDF}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h5" align="center">Area della Melia</Typography>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={melieIMG} 
                            title="Melie: Introduzione" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MELIAINTRODUZIONE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={meliaIMG} 
                            title="Melia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={MELIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cameliaIMG} 
                            title="Camelia" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={CAMELIA_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={cotognastroOrizzontaleIMG} 
                            title="Cotognastro Orizzontale" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={COTOGNASTROORIZZONTALE_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={eucaliptoIMG} 
                            title="Eucalipto" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={EUCALIPTO_PDF}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                        <CustomCard
                            image={ligustroIMG} 
                            title="Ligustro" 
                            content={""} 
                            buttonText={"Apri il PDF"}
                            pdf={LIGUSTRO_PDF}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </Box>
    )
}
