import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updatePage } from "../appSlice"
import { Header } from "../common/components/header"
import vialeLecciIMG from "../res/img/i_percorsi.jpeg"
import {
    Box,
    Stack,
    Typography
} from "@mui/material"
import Grid from '@mui/material/Unstable_Grid2';
import { CustomCard } from "../common/components/card";
import { IL_PERCORSO_DEGLI_ALBERI_SOPRAVVISUTI, IL_PERCORSO_DEI_GIUSTI, IL_PERCORSO_VIRGILIANO } from "../common/constants/paths";
import percorsoVirgilianoIMG from '../res/img/percorso_virgiliano_logo.jpg';
import percorsoDeiGiustiIMG from '../res/img/giardino_dei_giusti.jpg';
import percorsoAlberiSopravvisutiIMG from '../res/img/piante_sopravvisute.png';


export const Percorsi = ({}) => {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(updatePage("I Percorsi"));
    })

    return(
        <>
            <Header image={vialeLecciIMG} />
            <Box id="Percorsi-body" sx={{ marginTop:"20px", marginLeft: {xs: "5%", md: "15%"}, marginRight: {xs: "5%", md: "15%"}, marginBottom: "50px"}}>
                <Stack spacing={2}>
                    <Box>
                        <Typography variant="h4" align="center">I Percorsi</Typography>
                        <Typography align="justify">
                            I percorsi nascono dall'idea di visitare il Giardino, 
                            di guardare il suo mondo vegetale focalizzando l'attenzione anche sulle opere di artisti.
                            Si è andati alla ricerca di scrittori e poeti che le hanno ammirate e descritte, 
                            che hanno evidenziato la loro utilità per l'uomo, il loro 
                            legame con la mitologia, la loro storia. 
                        </Typography>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={percorsoVirgilianoIMG} 
                                title="Percorso Virgiliano" 
                                //content={"Percorso che segue le scritture del poeta Virgilio"} 
                                buttonText={"Vai alla sezione dedicata"}
                                path={ IL_PERCORSO_VIRGILIANO }
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={percorsoAlberiSopravvisutiIMG} 
                                title="Percorso Degli Alberi Sopravvisuti" 
                                // content={"Sezione in allestimento"}
                                buttonText={"Vai alla sezione dedicata"}
                                path={ IL_PERCORSO_DEGLI_ALBERI_SOPRAVVISUTI }    
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={percorsoDeiGiustiIMG} 
                                title="Percorso Dei Giusti" 
                                // content={"Sezione in allestimento"}
                                buttonText={"Vai alla sezione dedicata"}
                                path={ IL_PERCORSO_DEI_GIUSTI }
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>
        </>
    )
}
