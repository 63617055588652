import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { updatePage } from "../appSlice"
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { CustomCard } from "../common/components/card";
import { 
    ACERO_PDF, 
    ALLORO_PDF, 
    CASTAGNO_PDF, 
    CEDRO_PDF, 
    CIPRESSO_PDF, 
    CORBEZZOLO_PDF,
    GELSO_PDF, 
    GINEPRO_PDF, 
    GINESTRA_PDF, 
    IBISCO_PDF, 
    LECCIO_PDF, 
    LIGUSTRO_PDF, 
    LIMONE_PDF, 
    NOCE_PDF, 
    OLEANDRO_PDF, 
    OLIVO_PDF, 
    PINO_PDF, 
    PIOPPO_PDF,
    PRUGNO_PDF,
    PUNGITOPO_PDF,
    QUERCIA_PDF,
    ROSMARINO_PDF,
    SALICE_PDF,
    TAMERICE_PDF,
    TIGLIO_PDF,
    TIMO_PDF,
    VIBURNO_PDF
} from "../common/constants/paths";
import aceroIMG from "../res/img/acero.jpg";
import alloroIMG from "../res/img/alloro.jpg";
import castagnoIMG from "../res/img/castagno.jpg";
import cedroIMG from "../res/img/cedro.jpg";
import cipressoIMG from "../res/img/cipresso.jpg";
import corbezzoloIMG from "../res/img/corbezzolo.jpg";
import gelsoIMG from "../res/img/gelso.jpg";
import gineproIMG from "../res/img/ginepro.webp";
import ginestraIMG from "../res/img/ginestra.jpg";
import ibiscoIMG from "../res/img/ibisco.jpg";
import leccioIMG from "../res/img/leccio.jpg";
import limoneIMG from "../res/img/limone.jpg";
import ligustroIMG from "../res/img/ligustro.jpg";
import noceIMG from "../res/img/noce.jpg";
import OleandroIMG from "../res/img/oleandro.jpg";
import olivoIMG from "../res/img/olivo.jpg";
import pinoIMG from "../res/img/pino.jpg";
import pioppoIMG from "../res/img/pioppo.jpg";
import prugnoIMG from "../res/img/prugnolo_selvatico.jpg";
import pungitopoIMG from "../res/img/pungitopo.jpg";
import querciaIMG from "../res/img/quercia.jpg"
import rosmarinoIMG from "../res/img/rosmarino.jpg";
import saliceIMG from "../res/img/salice.jpg";
import tamericeIMG from "../res/img/tamerice.jpg";
import tiglioIMG from "../res/img/tiglio.jpg";
import timoIMG from "../res/img/timo.jpg";
import viburnoIMG from "../res/img/viburno.jpg";


export const IlPercorsoVirgiliano = ({}) => {

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(updatePage("Il Percorso Virgiliano"));
    })

    return(
        <>
            <Box id="Percorsi-body" sx={{ marginTop:"20px", marginLeft: {xs: "5%", md: "15%"}, marginRight: {xs: "5%", md: "15%"}, marginBottom: "100px"}}>
                <Stack spacing={2}>
                    <Typography variant="h4" align="center">Il Percorso Virgiliano</Typography>
                    <Typography align="justify">
                        Nella realizzazione del percorso all’interno del giardino biodiverso ci si è lasciati guidare
                        dalla consapevolezza che la natura costituisce da sempre una fonte privilegiata di
                        ispirazione per gli artisti, ognuno dei quali la interpreta secondo la propria cultura e
                        sensibilità.
                    </Typography>
                    <Typography align="justify">
                        Da qui l’idea di creare un itinerario guidato per guardare al mondo vegetale focalizzando
                        l’attenzione dal punto di vista delle opere degli artisti. Si è andati alla ricerca di scrittori e
                        poeti che hanno ammirato e descritto le piante presenti all’interno del nostro giardino e
                        che ne hanno evidenziato l’utilità per l’uomo, il loro legame con la mitologia e la loro
                        storia.
                    </Typography>
                    <Typography align="justify">
                        La valenza culturale di tale guida può essere individuata proprio nell’essere partiti dalla
                        descrizione delle piante del giardino biodiverso, dall’etimologia del nome scientifico per
                        poi immergersi nel mondo della letteratura e dell’arte.
                    </Typography>
                    <Typography align="justify">
                        Una sezione particolare del giardino è stata dedicata a Virgilio, che nelle sue opere più
                        celebri ci arricchisce di curiosità e peculiarità circa le suddette piante.
                    </Typography>
                    <Typography align="justify">
                        Nel giardino virgiliano non può mancare la descrizione dell’ingresso alla sede delle anime buone:
                    </Typography>
                    <Stack direction={"row"} spacing={5} p={3}>
                        <Typography align="justify">
                            “Devenere locos laetos et amoena virecta
                            Fortunatorum nemorum sedisque beatas” Eneide libro VI, vv 638-639
                        </Typography>
                        <Typography align="justify">
                            Trad: Arrivarono (Enea e la Sibilla) ai luoghi ridenti, alle amene verzure,
                            alle sedi beate dei boschi felici. (traduzione Rosa Calzecchi Onesti)
                        </Typography>
                    </Stack>
                    <Typography variant="h5" align="center">Paradiso e flora rigogliosa coincidono.</Typography>
                    <Typography align="justify">
                        La parola “paradiso”, attraverso il latino paradisus e il greco paradeisos, ha origine dal persiano pairidaeza che significa “giardino”.
                    </Typography>
                    <Stack direction={"row"} spacing={5} p={3}>
                        <Typography align="justify">
                            “Conspicit ecce alios dextra laevaque per herbam Vescentis laetumque choro
                             paeana canentis Inter odoratum lauri nemus” Eneide libro VI, vv 656-658.
                        </Typography>
                        <Typography align="justify">
                            Trad:(Enea e la Sibilla stanno ancora percorrendo le sedi delle anime buone) Ed ecco che
                            vede altri (le anime dei poeti) a destra e a sinistra per l'erba banchettare e cantare in coro
                            inni gioiosi alla divinità nel bosco profumato di alloro.
                        </Typography>
                    </Stack>
                    <Typography align="justify">
                        Il percorso Virgiliano è stato curato dai docenti Gennaro di Gennaro , Filomena Fusaro e Marika Piscopo.
                    </Typography>
                    <Typography variant="h5" align="center">Le piante del percorso</Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard
                                image={aceroIMG} 
                                title="Acero" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={ACERO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={alloroIMG}
                                title="Alloro" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={ALLORO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard
                                image={castagnoIMG} 
                                title="Castagno" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={CASTAGNO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={cedroIMG}
                                title="Cedro" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={CEDRO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={cipressoIMG}
                                title="Cipresso" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={CIPRESSO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={corbezzoloIMG}
                                title="Corbezzolo" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={CORBEZZOLO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={gelsoIMG}
                                title="Gelso" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={GELSO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={gineproIMG}
                                title="Ginepro"
                                buttonText={"Apri il PDF"}
                                pdf={GINEPRO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={ginestraIMG}
                                title="Ginestra" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={GINESTRA_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={ibiscoIMG}
                                title="Ibisco" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={IBISCO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={leccioIMG}
                                title="Leccio" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={LECCIO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={limoneIMG}
                                title="Limone" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={LIMONE_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={ligustroIMG}
                                title="Ligustro" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={LIGUSTRO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={noceIMG}
                                title="Noce" 
                                buttonText={"Apri il PDF"}
                                pdf={NOCE_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={OleandroIMG}
                                title="Oleandro" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={OLEANDRO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={olivoIMG}
                                title="Olivo" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={OLIVO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={pinoIMG}
                                title="Pino" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={PINO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={pioppoIMG}
                                title="Pioppo" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={PIOPPO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={prugnoIMG}
                                title="Prugno" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={PRUGNO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={pungitopoIMG}
                                title="Pungitopo" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={PUNGITOPO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={querciaIMG}
                                title="Quercia" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={QUERCIA_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={rosmarinoIMG}
                                title="Rosmarino" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={ROSMARINO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={saliceIMG}
                                title="Salice" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={SALICE_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={tamericeIMG}
                                title="Tamerice" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={TAMERICE_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={tiglioIMG}
                                title="Tiglio" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={TIGLIO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard  
                                image={timoIMG}
                                title="Timo" 
                                buttonText={"Apri il PDF"}
                                pdf={TIMO_PDF}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
                            <CustomCard 
                                image={viburnoIMG}
                                title="Viburno" 
                                content={""} 
                                buttonText={"Apri il PDF"}
                                pdf={VIBURNO_PDF}
                            />
                        </Grid>
                    </Grid>

                </Stack>
            </Box>
        </>
    )
}
