/** @jsxImportSource @emotion/react */
import {
    Divider,
    Stack,
    Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import { css } from '@emotion/react';

const style = css({
    backgroundColor: "#4F772D",
});

export const Footer = () => {
    return(
        <Grid container css={style}>
            <Grid xs={12} sm={12} md={12} lg={7} xl={7} sx={{margin: "20px"}}>
                <Stack direction="column" spacing={2} sx={{flexGrow: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <Typography color={"white"}>
                        Il giardino biodiverso del Liceo Miranda è inizialmente un progetto creato da Matteo Arnese come elaborato PCTO
                        per l'Esame di Stato A.S 2020/2021. Il progetto è open source, è stato creato con ReactJS e npm ed è disponibile 
                        al seguente link: https://github.com/xMattMaster/giardinomiranda.<br/>
                        La versione attuale è stata sviluppata a maggio 2023 da uno studente diplomato nell'anno scolastico
                        2012/2013.
                    </Typography>
                    <Typography color={"white"}>
                        È stato indispensabile il supporto della professoressa Mariagrazia Del Prete, che da decenni si 
                        impegna a portare avanti l'iniziativa del giardino biodiverso del Miranda con passione e costanza, 
                        facendo appassionare alla sua causa centinaia di studenti ogni anno, tra cui me. Il suo aiuto e la sua 
                        collaborazione sono stati vitali per la realizzazione di questo progetto. I suoi costanti sforzi, in ambito didattico e non,
                        durante i miei cinque anni trascorsi con lei hanno contribuito alla realizzazione di tutto ciò. Con questo progetto spero 
                        di ringraziarla di tutto.
                    </Typography>
                </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={4} xl={4} sx={{margin: "20px"}}>
                <Stack spacing={1} sx={{color:"white"}}>
                    <Typography>Liceo Scienttifico-Linguistico Statale "Carlo Miranda"</Typography>
                    <Typography>Via F.A. Giordano, 91</Typography>
                    <Typography>80027 Frattamaggiore(NA)</Typography>
                    <Typography>Tel. +39 081 8801909</Typography>
                    <Typography>https://www.liceocarlomiranda.edu.it/</Typography>
                </Stack>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={12} xl={12} color="white" sx={{display: "flex", justifyContent:"center", alignItems:"center", marginBottom: "10px"}}>
                <Stack spacing={1} sx={{width: "100%"}}>
                    <Divider sx={{background: "#C5C7C9", width: "100%"}}/>
                    <Typography textAlign={"center"}>Liceo Scientifico Carlo Miranda | 2023 </Typography>
                </Stack>
            </Grid>
        </Grid>
    )
}