/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react'
import {
    AppBar,
    Box,
    Drawer,
    IconButton,
    List,
    ListItem,
    Toolbar,
    Typography
} from '@mui/material'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';

import { css } from '@emotion/react';

const backgroundColor = css({
    backgroundColor: "#4F772D"
});

const linkStyle = {
    margin: "1rem",
    textDecoration: "none",
    color: "white"
}

const drawerLinkStyle = {
    textDecoration: "none",
    color: "black"
}


export const NavBar = ({selectedPageName}) => {

    const [pageName, setPageName] = useState("")
    const pages = [
        // ["Homepage", "/"],
        // ["Il Giardino", "/il-giardino"], 
        ["Il Giardino", "/"], 
        ["I Percorsi", "/i-percorsi"], 
        ["Le Schede Botaniche", "/le-schede-botaniche"]
    ];

    useEffect(()=>{
        setPageName(selectedPageName)
    }, [selectedPageName]);

    const [open, setOpen] = useState(false);

    return(
        <AppBar position='static'>
            <Toolbar>
                <Box sx={{flexGrow: 1, display: { xs: "flex", md: "none" }}}>
                    <IconButton onClick={() => {setOpen(!open)}} ><MenuIcon sx={{color: "white"}}/></IconButton>
                    <Typography
                        variant='h6'
                        noWrap
                        sx={{ alignSelf: "center" }}
                    >{pageName}</Typography>
                </Box>
                <Box sx={{flexGrow: 1, display: { xs: "none", md: "flex"}, marginLeft: "2rem"}}>
                    {pages.map( (page) => {
                        const key = page[0].replace(' ', '');
                        return(
                            <Link key={key} to={page[1]} style={linkStyle} ><Typography>{page[0]}</Typography></Link>
                        )
                    })}
                </Box>
            </Toolbar>
            <Drawer
                anchor='left'
                open={open}
                onClose={() => {setOpen(!open)}}
            >
                <Box
                    css={backgroundColor}
                    sx={{width: 250, height: "100%"}}
                    role="presentation"
                >
                    <List>
                        {pages.map((page) => {
                            const key = page[0].replace(' ', '-');
                            return(
                                <ListItem key={key}>
                                    <Link to={page[1]} style={drawerLinkStyle} onClick={() => {setOpen(!open)}}>
                                        <Typography color="white">{page[0]}</Typography>
                                    </Link>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Drawer>
        </AppBar>
    )
}
