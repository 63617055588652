import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Homepage } from './homepage/homepage';
import { IlGiardino } from './giardino/ilGiardino'
import { IlPercorsoDegliAlberiSopravvisuti } from './percorso_alberi_sopravvissuti/ilPercorsoDegliAlberiSopravvisuti'
import { IlPercorsoDeiGiusti } from './percorso_dei_giusti/ilPercorsoDeiGiusti';
import { IlPercorsoVirgiliano } from './percorso_virgiliano/ilPercorsoVirgiliano'
import { LeSchedeBotaniche } from './schede_botaniche/leSchedeBotaniche'
import { NavBar } from './common/components/navbar';
import { Footer } from './common/components/footer';
import { Percorsi } from './percorsi/iPercorsi';
import {
  ROOT,
  IL_GIARDINO,
  IL_PERCORSO_DEGLI_ALBERI_SOPRAVVISUTI,
  IL_PERCORSO_DEI_GIUSTI,
  IL_PERCORSO_VIRGILIANO,
  I_PERCORSI,
  LE_SCHEDE_BOTANICHE
} from './common/constants/paths'

function App() {
  const pageName = useSelector((state) => state.giardinomiranda.selectedPage);

  return (
    <BrowserRouter>
      <NavBar selectedPageName={pageName}/>
      <Routes>
        {/* <Route 
          path={ROOT} 
          element={<Homepage/>}
        /> */}
        <Route 
          path={ROOT}
          element={<IlGiardino/>}
        />
        <Route 
          path={I_PERCORSI} 
          element={<Percorsi/>}
        />
        <Route
          path={IL_PERCORSO_DEGLI_ALBERI_SOPRAVVISUTI}
          element={<IlPercorsoDegliAlberiSopravvisuti/>}
        />
        <Route
          path={IL_PERCORSO_DEI_GIUSTI}
          element={<IlPercorsoDeiGiusti/>}
        />
        <Route
          path={IL_PERCORSO_VIRGILIANO}
          element={<IlPercorsoVirgiliano/>}
        />
        <Route
          path={LE_SCHEDE_BOTANICHE}
          element={<LeSchedeBotaniche/>} 
        />
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
