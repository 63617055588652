import { createTheme } from "@mui/material/styles";
import { palette } from "@mui/system";

const theme = createTheme();

const giardinoMirandaTheme = createTheme({
    palette: {
        primary: { main: "#4F772D" },
        background: { default: "#FFFAD2" },
    }
});

export default giardinoMirandaTheme;