import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import {ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import giardinoMirandaTheme from "./common/styles/giardinoMirandaTheme";
import { CssBaseline } from '@mui/material/';
import App from './App';
import store from './store';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={giardinoMirandaTheme}>
        <CssBaseline/>
        <Provider store={store}>
          <App/>
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.StrictMode>
);
